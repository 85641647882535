<template>
  <v-card width="100%">
    <!-- <v-card-title class="text-h5 mb-2 justify-center" style="background-color: #d9c7A3"
      ><v-spacer />
      {{ $t('organizations.title') }}
      <v-spacer /> <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title
    > -->
    <v-toolbar color="biscuit" class="mb-2">
      <v-spacer />
      <v-toolbar-title>   
        {{ $t('organizations.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>

      <v-card class="elevation-0">
        <v-card-title >
            <a href="https://www.lifeneophron.eu/#home" target="_blank">
              <v-img :src="require('@/assets/logo_smaller.png')" width="180" />
            </a>
          <!-- <v-spacer /> -->
          &nbsp;&nbsp;
            Egyptian Vulture New LIFE Project
          <v-spacer />
        </v-card-title>
        <v-card-text>
          The project "Urgent Actions to Strengthen the Balkan Population of the Egyptian Vulture and Secure Its Flyway” (LIFE16 NAT/BG/000874), abbreviated to <b>Egyptian Vulture New LIFE Project</b>, is being implemented by institutions and organisations from 14 countries across the Balkans, Middle East and Africa in order to save the last pairs of Egyptian vultures in the Balkans and secure a safe flyway for them.
          The project -lasting from 2017 till 2022- is implemented with financial support from the EU's LIFE Programme and the co-financing of the AG Leventis Foundation and the MAVA Foundation.
        </v-card-text>
        <v-card-text class="text-center">
          <h3>Partners</h3>
        </v-card-text>
        <v-card-text>
        <v-row dense>
          <v-col
            v-for="(organization, i) in asproparis"
            :key="i"
            :cols="organization.flex"
            md=2
            sm=2
          >
                
            <v-card>
              <v-card-text class="justify-center">
                
                <a :href="organization.link" target="_blank">
                <v-img
                  :src="organization.logo"
                max-height="150"
                max-width="200"
                />
                </a>
              </v-card-text>
              <!-- REMOVE Organization text for the time being -->
              <!-- <v-card-text>{{ organization.text }}</v-card-text> -->
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>

        <!-- ASPROPARIS DONORS -->
        <v-card-text class="text-center">
          <h3>Donors</h3>
        </v-card-text>
        <v-card-text>
        <v-row dense>
          <v-col
            v-for="(organization, i) in asproparisdonors"
            :key="i"
            :cols="organization.flex"
            md=2
            sm=2
          >
                
            <v-card>
              <v-card-text class="justify-center">
                
                <a :href="organization.link" target="_blank">
                <v-img
                  :src="organization.logo"
                max-height="150"
                max-width="200"
                />
                </a>
              </v-card-text>
              <!-- REMOVE Organization text for the time being -->
              <!-- <v-card-text>{{ organization.text }}</v-card-text> -->
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>

      <v-divider class="ma-2" />

      <v-card class="elevation-0">
        <v-card-title>             
            <a href="https://balkandetoxlife.eu/" target="_blank">
              <v-img :src="require('@/assets/logo_balkandetox.png')" width="150" />
            </a>
          <!-- <v-spacer /> -->
          &nbsp;&nbsp;
            BalkanDetox LIFE Project 
          <v-spacer />
        </v-card-title>
        <v-card-text>
          The project " Strengthen national capacities to fight wildlife poisoning and raise awareness about the problem in the Balkan countries” (LIFE19 GIE/NL/001016), with its acronym <b>BalkanDetox LIFE Project</b>, 
          is a five-year endeavour intending to improve the management of poisoning incidents and significantly reduce the mortality of vultures and other affected species caused by the illegal use of poison 
          baits across seven countries in the Balkans. 
          The project is funded by the EU's LIFE Programme, and co-financed by the Vulture Conservation Foundation, the MAVA Foundation and Euronatur.
        </v-card-text>
        <v-card-text class="text-center">
          <h3>Partners</h3>
        </v-card-text>
        <v-card-text>
        <v-row dense>
          <v-col
            v-for="(organization, i) in balkandetox"
            :key="i"
            :cols="organization.flex"
            md=2
            sm=2
          >
            <v-card>
              <v-card-text class="justify-center">
                <a :href="organization.link" target="_blank">
                <v-img
                  :src="organization.logo"
                max-height="150"
                max-width="200"
                />
                </a>
              </v-card-text>
              <!-- REMOVE Organization text for the time being -->
              <!-- <v-card-text>{{ organization.text }}</v-card-text> -->
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>

        <!-- BALKANDETOX DONORS -->
        <v-card-text class="text-center">
          <h3>Donors</h3>
        </v-card-text>
        <v-card-text>
        <v-row dense>
          <v-col
            v-for="(organization, i) in balkandetoxdonors"
            :key="i"
            :cols="organization.flex"
            md=2
            sm=2
          >
                
            <v-card>
              <v-card-text class="justify-center">
                
                <a :href="organization.link" target="_blank">
                <v-img
                  :src="organization.logo"
                max-height="150"
                max-width="200"
                />
                </a>
              </v-card-text>
              <!-- REMOVE Organization text for the time being -->
              <!-- <v-card-text>{{ organization.text }}</v-card-text> -->
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
      
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "Organizations",
  data: () => ({
    asproparis: [
      {
        name: 'test1',
        logo: require('@/assets/logos/logo_bspb.jpg'),
        text: 'BSPB',
        link: 'https://bspb.org/en/',
        flex: 4
      },
      {
        name: 'test1',
        logo: require('@/assets/logos/logo_raptors_mou.jpg'),
        text: 'RAPTORS MOU',
        link: 'http://www.cms.int/raptors/en',
        flex: 4
      },
      {
        name: 'test1',
        logo: require('@/assets/logos/logo_cms.jpg'),
        text: 'CMS',
        link: 'http://www.cms.int/raptors/en',
        flex: 4
      },
      {
        name: 'Ornithologiki',
        logo: require('@/assets/logos/logo_ornithologiki.jpg'),
        text: 'HOS',
        link: 'http://www.ornithologiki.gr/index.php?loc=en',
        flex: 4
      },
      {
        name: 'test2',
        logo: require('@/assets/logos/logo_wwf.jpg'),
        text: 'WWF Greece',
        link: 'http://www.wwf.gr/en/',
        flex: 4
      },
      {
        name: 'Royal Society for the Protection of Birds',
        logo: require('@/assets/logos/logo_rspb.jpg'),
        text: 'RSPB',
        link: 'http://www.rspb.org.uk/',
        flex: 4
      },
      {
        name: 'Birdlife middle East',
        logo: require('@/assets/logos/logo_birdlife_middle_east.jpg'),
        text: 'some description',
        link: 'https://www.birdlife.org/middle-east',
        flex: 4
      },
      {
        name: 'Birdlife Africa',
        logo: require('@/assets/logos/logo_birdlife_africa.jpg'),
        text: 'some description',
        link: 'https://www.birdlife.org/africa',
        flex: 4
      },
      {
        name: 'Doga',
        logo: require('@/assets/logos/logo_doga.jpg'),
        text: 'some description',
        link: 'https://www.dogadernegi.org/en',
        flex: 4
      },
      {
        name: 'Green balkans',
        logo: require('@/assets/logos/logo_green_balkans.png'),
        text: 'Green Balkans',
        link: 'http://www.greenbalkans.org/en/',
        flex: 4
      },
      {
        name: 'APLORI',
        logo: require('@/assets/logos/logo_apleventis_ori.jpg'),
        text: 'some description',
        link: 'https://aplori.org/',
        flex: 4,
        function: 'donor',
      },
    ],
    asproparisdonors: [
      {
        name: 'Life',
        logo: require('@/assets/logos/logo_life.jpg'),
        text: 'some description',
        link: 'https://cinea.ec.europa.eu/life_en',
        flex: 4,
        function: 'donor',
      },
      {
        name: 'Natura',
        logo: require('@/assets/logos/logo_natura2000.jpg'),
        text: 'some description',
        link: 'https://cinea.ec.europa.eu/life_en',
        flex: 4,
        function: 'donor',
      },
      {
        name: 'test4',
        logo: require('@/assets/logos/logo_agleventis_foundation.jpg'),
        text: 'Leventis Foundation',
        link: 'https://www.leventisfoundation.org/',
        flex: 4,
        function: 'donor',
      },
      {
        name: 'MAVA',
        logo: require('@/assets/logos/logo_mava_fondation.jpg'),
        text: 'some description',
        link: 'https://mava-foundation.org/',
        flex: 4,
        function: 'donor',
      },
      {
        name: 'GreenFund',
        logo: require('@/assets/logos/logo_green_fund.jpg'),
        text: 'GreenFund',
        link: 'https://prasinotameio.gr/',
        flex: 4,
        function: 'donor',
      },
    ],
  balkandetox: [
      {
        name: 'VCF',
        logo: require('@/assets/logos/logo_vcf.jpg'),
        text: 'Vulture Conservation Foundation',
        link: 'http://www.4vultures.org',
        flex: 4
      },
      {
        name: 'AOS',
        logo: require('@/assets/logos/logo_aos.jpg'),
        text: 'Albanian Ornithological Society',
        link: 'https://aos-alb.org/',
        flex: 4
      },
      {
        name: 'BIOM',
        logo: require('@/assets/logos/logo_biom.jpg'),
        text: 'Association BIOM',
        link: 'https://www.biom.hr/en/',
        flex: 4
      },
      {
        name: 'test2',
        logo: require('@/assets/logos/logo_dzpps_serbia.jpg'),
        text: 'Bird Protection and Study Society of Serbia',
        link: 'https://pticesrbije.rs/',
        flex: 4
      },
      {
        name: 'Fund for Wild Flora and Fauna',
        logo: require('@/assets/logos/logo_fdff.jpg'),
        text: 'Fund for Wild Flora and Fauna',
        link: 'https://fwff.org/',
        flex: 4
      },
      {
        name: 'Ornithologiki',
        logo: require('@/assets/logos/logo_ornithologiki.jpg'),
        text: 'HOS',
        link: 'http://www.ornithologiki.gr/index.php?loc=en',
        flex: 4
      },
      {
        name: 'Macedonian Ecological Society',
        logo: require('@/assets/logos/logo_mes.jpg'),
        text: 'Macedonian Ecological Society',
        link: 'https://mes.org.mk/en/',
        flex: 4
      },
      {
        name: 'Ornitološko društvo NAŠE Ptice',
        logo: require('@/assets/logos/logo_nase_ptice.jpg'),
        text: 'Ornitološko društvo NAŠE Ptice',
        link: 'https://ptice.ba/en/ornithological-society-nase-ptice/',
        flex: 4
      },
      {
        name: 'PPNEA',
        logo: require('@/assets/logos/logo_ppnea.jpg'),
        text: 'Protection and Preservation of Natural Environment in Albania',
        link: 'https://ppnea.org/',
        flex: 4
      },
  ],
  balkandetoxdonors: [
      {
        name: 'Life',
        logo: require('@/assets/logos/logo_life.jpg'),
        text: 'some description',
        link: 'https://cinea.ec.europa.eu/life_en',
        flex: 4
      },
      {
        name: 'MAVA',
        logo: require('@/assets/logos/logo_mava_fondation.jpg'),
        text: 'some description',
        link: 'https://mava-foundation.org/',
        flex: 4
      },      
      {
        name: 'Euronatur',
        logo: require('@/assets/logos/logo_euronature.jpg'),
        text: 'Euronatur', 
        link: 'https://www.euronatur.org/en',
        flex: 4
      }
    ]
  }),
  methods: {
    close () {
      this.$emit("closedialog", false)
    }
  },
  computed: {},
};
</script>
